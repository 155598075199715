/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { useCallback, useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'

import { ECampaignSource } from '@common/lead-source-context'
import {
  Button,
  Notification,
  onScrollFinish,
  RadioGroup,
  useShake,
} from '@common/react-lib-consumer-pres'
import {
  Citizenship,
  citizenshipDisplay,
  ERelationship,
  NewPersonalFormModule as PersonalFormModule,
  PageRoute,
  SelectFieldProps,
} from '@common/types'
import { useDirectMailUserNameInfo, useEffectIf, useLogger } from '@src/hooks'
import {
  LeadSource,
  setAuthToken,
  setLeadSource,
  setPrimaryCustomerUuid,
  setRequestId,
} from '@src/utils/storage'

import {
  convertDirectMailRecipientToCustomer,
  customerAdapters,
  partnerPrefillContext,
  saveCustomerRewardsInfo,
  saveDirectMailLookupCode,
} from '../../../api'
import {
  trackCoBorrowerSectionCollapsed,
  trackCoBorrowerSectionExpanded,
  trackPersonalInfoSubmitted,
  useTrackAbandon,
} from '../../../tracking'
import {
  DateField,
  Element,
  genericErrorMessage,
  NewTextField as TextField,
  SelectField,
  useModule,
} from '../../utils'
import { PhoneInput } from '../controlled-fields'

import { usePersonalInfoForm } from './form'
import {
  useDirectMailCodePrefillPersonalInfo,
  usePrefillCustomerData,
} from './prefill'
import { segmentIdentify } from './tracking'
import { useBorrowerEmailField } from './use-borrower-email'
import { useCoborrowerEmail } from './use-coborrower-email'

type PersonalInformationForm = {
  module: PersonalFormModule
}

const residencyStatus: SelectFieldProps['menuItems'] = [
  {
    value: '',
    displayName: 'Select a status',
  },
  {
    value: Citizenship.UsCitizen,
    displayName: citizenshipDisplay[Citizenship.UsCitizen],
  },
  {
    value: Citizenship.PermanentResident,
    displayName: citizenshipDisplay[Citizenship.PermanentResident],
  },
  {
    value: Citizenship.NonPermanentResident,
    displayName: citizenshipDisplay[Citizenship.NonPermanentResident],
  },
  {
    value: Citizenship.NonResidentAlien,
    displayName: citizenshipDisplay[Citizenship.NonResidentAlien],
  },
]

export const usePersonalInformationFormModule = (): PersonalInformationForm => {
  const form = usePersonalInfoForm()
  const {
    control,
    watch,
    setValue,
    formState,
    trigger,
    handleSubmit,
    submit: submitForm,
    submitWithCoborrower: submitFormWithCoborrower,
  } = form
  const { isSubmitting, isSubmitSuccessful } = formState

  const router = useRouter()
  const logger = useLogger()
  const { query } = router
  const { userInfo, userInfoLoaded } = useDirectMailUserNameInfo()
  const { customerInfo, rewardsInfo, dataLoaded } = useContext(
    partnerPrefillContext,
  )
  usePrefillCustomerData(setValue, { customerInfo, dataLoaded })
  useDirectMailCodePrefillPersonalInfo(setValue, { userInfo, userInfoLoaded })
  const [submitError, setSubmitError] = useState('')
  const hideWarning = useCallback(() => setSubmitError(''), [])

  const {
    element: EmailField,
    errorComponent: EmailExistError,
    errorRef,
    showCustomError,
  } = useBorrowerEmailField({
    staticProps: { label: 'Email address' },
    control,
    trigger,
  })
  const removeUnload = useTrackAbandon()
  const shake = useShake()
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.VehicleLookup)
    }
    prefetch().catch(console.error)
  }, [])

  useEffectIf(query.dm_code && query.zip, () => {
    void saveDirectMailLookupCode(query.dm_code as string, query.zip as string)
  })

  const submit = handleSubmit(
    async (values): Promise<void> => {
      try {
        const customer = values.cosigner
          ? await submitFormWithCoborrower(values)
          : await submitForm(values)

        const coborrowerUuid = values.cosigner
          ? (customer as customerAdapters.CreateCustomerWithCoborrowerResponse)
              .coborrowerUuid
          : undefined

        setAuthToken(customer.authId)
        setRequestId(customer.requestId)
        setLeadSource(LeadSource.MR_CONSUMER_SITE)
        segmentIdentify(customer.segmentUserId, {
          ...values,
          customerUuid: customer.uuid,
          coborrowerUuid: coborrowerUuid,
          resumeUrl: customer.resumeUrl,
          newResumeUrl: customer.newResumeUrl,
        })
        setPrimaryCustomerUuid(customer.uuid, logger)
        if (
          query?.utm_source === ECampaignSource.directMessage &&
          query?.dm_code
        ) {
          void convertDirectMailRecipientToCustomer({
            code: query.dm_code as string,
            zip: query.zip as string,
          })
        }

        void trackPersonalInfoSubmitted({
          userId: Number.parseInt(customer.segmentUserId),
        })

        if (!isEmpty(rewardsInfo)) {
          void saveCustomerRewardsInfo(customer.uuid, rewardsInfo)
        }

        removeUnload()
        void router.push({
          pathname: '/vehicle-info',
          query,
        })
      } catch (error) {
        setSubmitError(genericErrorMessage)
        throw error
      }
    },
    () => {
      if (showCustomError) {
        errorRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        onScrollFinish(() => {
          shake(errorRef.current)
        })
      }
    },
  )

  const CoEmailField = useCoborrowerEmail({ label: 'Email address' }, form)

  const watchCosigner = watch('cosigner') ?? false
  control.register('cosigner')

  return {
    module: useModule({
      SubmitError:
        !!submitError &&
        Element(Notification, {
          type: 'error',
          title: 'Error',
          body: submitError,
          onClose: hideWarning,
        }),
      Submit: Element(Button, {
        'data-testid': 'personal-info-submit',
        onClick: submit,
        loading: isSubmitting || isSubmitSuccessful,
        children: 'Continue',
      }),

      SelectCosigner: Element(RadioGroup, {
        value: watchCosigner,
        items: [
          {
            label: 'No one else, just me',
            value: false,
            dataTestId: 'radio-no-one-else',
          },
          {
            label: 'Yes, I have a co-borrower or co-signer',
            value: true,
            dataTestId: 'radio-co-borrower',
          },
        ],
        name: 'cosigner',
        control,
        onChange: (v: boolean) => {
          setValue('cosigner', v, { shouldValidate: true })
          if (v) {
            void trackCoBorrowerSectionExpanded()
          } else {
            void trackCoBorrowerSectionCollapsed()
          }
        },
      }),

      borrower: {
        FirstName: Element(TextField, {
          label: 'First name',
          control,
          name: 'firstName',
          'data-testid': 'firstName',
        }),
        LastName: Element(TextField, {
          label: 'Last name',
          control,
          name: 'lastName',
          'data-testid': 'lastName',
        }),
        Email: EmailField,
        EmailExistError,
        ConfirmEmail: Element(TextField, {
          label: 'Confirm email address',
          control,
          disabled: showCustomError,
          name: 'confirmEmailAddress',
          'data-testid': 'confirmEmailAddress',
          onBlur: (e) => {
            setValue('confirmEmailAddress', e?.target?.value?.trim(), {
              shouldValidate: true,
            })
          },
        }),

        Cellphone: Element(PhoneInput, {
          label: 'Mobile phone number',
          control,
          name: 'cellphone',
          'data-testid': 'cellPhone',
        }),
        Birthdate: Element(DateField, {
          label: 'Date of birth',
          control,
          name: 'dob',
          placeholder: 'mm/dd/yyyy',
          'data-testid': 'birthdate',
        }),
        Citizenship: Element(SelectField, {
          control,
          name: 'citizenship',
          label: 'What is your U.S. residency status?',
          'data-testid': 'citizenship',
          menuItems: residencyStatus,
        }),
      },
      coborrower: watchCosigner && {
        FirstName: Element(TextField, {
          label: 'First name',
          control,
          name: 'cofirstName',
          'data-testid': 'cofirstName',
        }),
        LastName: Element(TextField, {
          label: 'Last name',
          control,
          name: 'colastName',
          'data-testid': 'colastName',
        }),
        Email: CoEmailField,
        ConfirmEmail: Element(TextField, {
          label: 'Confirm email address',
          control,
          name: 'coconfirmEmailAddress',
          'data-testid': 'coconfirmEmailAddress',
          onBlur: (e) => {
            setValue('coconfirmEmailAddress', e.target.value.trim(), {
              shouldValidate: true,
            })
          },
        }),
        Cellphone: Element(PhoneInput, {
          label: 'Mobile phone number',
          control,
          name: 'cocellphone',
          'data-testid': 'cocellPhone',
        }),
        Birthdate: Element(DateField, {
          label: 'Date of birth',
          control,
          name: 'codob',
          'data-testid': 'cobirthdate',
          placeholder: 'mm/dd/yyyy',
        }),
        Citizenship: Element(SelectField, {
          control,
          name: 'cocitizenship',
          'data-testid': 'cocitizenship',
          label: 'U.S. residency status of co-borrower',
          menuItems: residencyStatus,
        }),
        Relationship: Element(SelectField, {
          control,
          label: 'What is your relationship to the co-borrower?',
          name: 'relationship',
          'data-testid': 'relationship',
          menuItems: [
            {
              displayName: 'Select an option',
              value: '',
            },
            {
              displayName: 'Spouse',
              value: ERelationship.spouse,
            },
            {
              displayName: 'Child',
              value: ERelationship.child,
            },
            {
              displayName: 'Parent',
              value: ERelationship.parent,
            },
            {
              displayName: 'Significant Other',
              value: ERelationship.significantOther,
            },
            {
              displayName: 'Friend',
              value: ERelationship.friend,
            },
          ],
        }),
      },
    }),
  }
}
