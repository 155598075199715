import React from 'react'
import { Box, styled } from '@mui/material'

import { Text } from '../../reskin'

const TermsContainer = styled(Box)(({ theme }) => ({
  ...theme.extension.typography.paragraphs[18],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '8px',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(-4),
  [theme.breakpoints.down('md')]: {
    ...theme.extension.typography.paragraphs[16],
    margin: 0,
    marginBottom: theme.spacing(-1),
  },
}))

const TermsText = styled('p')({
  flex: 'none',
  order: 0,
  flexGrow: 0,
  margin: 0,
  fontSize: '12px',
  lineHeight: '24px',
})

const defaultProps = {
  disclosures: [
    '*This estimated monthly payment is calculated using a 30 day period to first payment. This estimated monthly payment could vary with the final monthly payment displayed in your lender’s contract depending upon the number of days your lender allows until your first monthly payment.',
    '1. This offer is valid for 30 days. Rates are not guaranteed and are subject to change without notice.',
    '2. Estimated based on Transunion report data.',
    '3. Based on a comparison with your existing loan.',
    '4. Your new amount financed will include a $499 - $559 processing fee for services and retitling your vehicle.',
    '5. The Estimated Annual Percentage Rate is the total yearly cost of borrowing money. APR is expressed as a percentage and includes the fees charged for the extension of credit.',
  ],
}

export const OfferTerms: React.FC = () => {
  return (
    <TermsContainer>
      <Text use={['p', 'subheadings.14sb']} color="text.softBlack" margin={0}>
        DISCLOSURES
      </Text>
      {defaultProps.disclosures.map((disclosure, i) => (
        <TermsText key={i}>{disclosure}</TermsText>
      ))}
    </TermsContainer>
  )
}
