import { SplitFactory } from '@splitsoftware/splitio-react'
import featureFlags from 'split_io.json'

import { splitIoAPIKey, splitIoId } from '@src/config'

const SDKConfig: SplitIO.IBrowserSDK = SplitFactory({
  core: {
    authorizationKey: splitIoAPIKey,
    key: splitIoId,
  },
  features: {
    OFFER_REDESIGN: featureFlags.OFFER_REDESIGN.treatment,
  },
})

export default SDKConfig
