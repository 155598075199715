/* eslint-disable @typescript-eslint/no-use-before-define */
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, { FunctionComponent, useEffect } from 'react'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import isNil from 'lodash/isNil'
import { AppInitialProps, AppProps } from 'next/app'
import Head from 'next/head'
import { NextRouter, useRouter, withRouter } from 'next/router'
import Script from 'next/script'
import { pipeWith } from 'pipe-ts'

import SDKConfig from '@common/feature-flag/splitio'
import { getDatadogLogs } from '@common/telemetry'
import { createEmotionCache } from '@lib/mui'
import { oldTheme } from '@modules/core-ui'
import ErrorBoundary from '@src/components/error-boundary'
import { googleMapsApiKey } from '@src/config'
import { safeWindow } from '@src/utils'

import {
  AuthProvider,
  DatadogProvider,
  LeadSourceProvider,
  Logger,
  PartnerDataProvider,
  partnerPrefillContext,
  useAuthGuard,
  useLogger,
  usePartnerPrefill,
  useTrackPageChange,
  withProviders,
} from '../src'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()
getDatadogLogs()

// split.io client
const splitIoClientKey = SDKConfig

type MyAppProps = AppInitialProps &
  AppProps & { router: NextRouter; emotionCache?: EmotionCache } & {
    logger: Logger
  }

const MyApp: FunctionComponent<MyAppProps> = (props) => {
  // From https://git.io/JeWPo
  // And https://material-ui.com/guides/server-rendering/#the-client-side
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (!isNil(jssStyles)) {
      jssStyles.remove()
    }
  }, [])

  const logger = useLogger()
  useAuthGuard(logger)

  useTrackPageChange(props.router)
  return <Main {...props} logger={logger} />
}

const Main: FunctionComponent<MyAppProps> = (props) => {
  const prefillData = usePartnerPrefill()
  const router = useRouter()
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    logger,
  } = props

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      safeWindow.scroll({
        top: 0,
        left: 0,
      })
    })
  }, [])

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <title key="title">{'Caribou'}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <ThemeProvider theme={oldTheme}>
          <SplitFactoryProvider factory={splitIoClientKey}>
            <ErrorBoundary>
              <CssBaseline />
              <LeadSourceProvider query={router.query}>
                <PartnerDataProvider logger={logger}>
                  <partnerPrefillContext.Provider value={prefillData}>
                    <Component {...{ router, ...pageProps }} />
                  </partnerPrefillContext.Provider>
                </PartnerDataProvider>
              </LeadSourceProvider>
            </ErrorBoundary>
          </SplitFactoryProvider>
        </ThemeProvider>
      </CacheProvider>
      <Script
        strategy="lazyOnload"
        src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
        async
      />
    </>
  )
}

export default pipeWith(
  MyApp,
  withRouter,
  withProviders(DatadogProvider, AuthProvider),
)
