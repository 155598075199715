import React from 'react'
import { Box, styled } from '@mui/material'

import { caribouPhone } from '@src/config'

import { Link, SaleDuotoneIcon, Text } from '../../reskin'

const CallToActionContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.extension.colors.divider.lightGray}`,
  borderRadius: '12px',
  margin: theme.spacing(6, 0, 4, 0),
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(4.5, 0, 3),
    padding: theme.spacing(2),
  },
}))

const CallToActionText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    display: 'block',
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(-0.5),
  },
}))

const CallUsText = styled(Link)(({ theme }) => ({
  ...theme.extension.typography.subheadings['18b'],
  [theme.breakpoints.down('md')]: {
    ...theme.extension.typography.subheadings['16b'],
  },
}))

export const CallToAction: React.FC = () => {
  return (
    <CallToActionContainer>
      <CallToActionText>
        <IconContainer>
          <SaleDuotoneIcon />
        </IconContainer>
        <Text
          use={['h1', { xs: 'paragraphs.16', sm: 'paragraphs.18' }]}
          color="text.softBlack"
          margin={0}
        >
          {
            'Additional terms may be available. Please contact a loan officer at '
          }
          <CallUsText href={`tel:${caribouPhone}`}>{caribouPhone}</CallUsText>
        </Text>
      </CallToActionText>
    </CallToActionContainer>
  )
}
