import React, { FC } from 'react'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { OfferSelectionsFormProps } from '@common/types'

import { ContinueButton, Text } from '../../reskin'

import { CallToAction } from './call-to-action'
import { CurrentLoanInfo } from './current-loan-info'
import { CardsCarousel } from './loan-carousel'
import { OfferSelectionsHeader } from './offer-selections-components'
import { OfferTerms } from './offer-terms'

const CardContainer = styled(Box)({
  display: 'flex',
})

const Divider = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
}))

const Section = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(6),
  borderBottom: `1px solid ${theme.extension.colors.divider.lightGray}`,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

const HeroContainer = styled(Box)({
  position: 'relative',
})

export const OfferSelectionsForm: FC<OfferSelectionsFormProps> = (props) => {
  const {
    module: { LowestOption, FlexibleOptions },
    currentLoanInfo,
    Submit,
    SubmitError,
    onCarouselSwipeTo,
  } = props

  const theme = useTheme()
  const showCarousel = useMediaQuery(theme.breakpoints.down(750))

  return (
    <>
      <HeroContainer>
        <OfferSelectionsHeader />
        {SubmitError && <SubmitError sx={{ mt: 4, mb: -2 }} />}
        <Text
          use={['h1', { xs: 'headings.32', sm: 'headings.48' }]}
          color="text.white"
          my={{ xs: 4, sm: 6 }}
        >
          Choose your new loan
        </Text>
      </HeroContainer>
      <Section>
        <CurrentLoanInfo {...currentLoanInfo} />
        {showCarousel ? (
          <CardsCarousel
            LowestOption={LowestOption}
            FlexibleOptions={FlexibleOptions}
            onSwipeTo={onCarouselSwipeTo}
          />
        ) : (
          <CardContainer>
            <LowestOption />
            <Divider />
            <FlexibleOptions />
          </CardContainer>
        )}
      </Section>

      <Box mt={5}>
        <ContinueButton button={Submit} />
      </Box>
      <CallToAction />
      <Section />
      <OfferTerms />
    </>
  )
}
