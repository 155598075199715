import { AdditionalInformationNeededForm } from 'src/pages/the-zebra/additional-information-needed'

import { isPromiseRejected } from '@common/lib-types'
import {
  SubmittedVehicle,
  VehicleFindBy,
  VehicleFindByKeys,
} from '@common/types'
import { Common } from '@common/types/api/vehicle'
import { Logger } from '@src/hooks/use-logger'
import { parseInteger } from '@src/utils'
import { getAuthToken } from '@src/utils/storage'

import {
  DynamicVehicleFieldsLP,
  DynamicVehicleFieldsVin,
  DynamicVehicleFieldsYMMT,
} from '..'
import { consumerXpAggregatorUrl } from '../config'

import { refiUpdateCustomer } from './customer'
import { refiUpsertCurrentEmployment } from './employment'
import { DocumentType, insertLegalAcceptance } from './legal-acceptance'
import { updateLoanApplication } from './loan-application'
import { updateCurrentResidence } from './residence'
import { refiUpsertVehicle } from './vehicle'

type DynamicVehicleFields =
  | DynamicVehicleFieldsYMMT
  | DynamicVehicleFieldsVin
  | DynamicVehicleFieldsLP

export type MissingInfoFormParams = {
  estimatedAnnualIncome: string
  citizenship: string
  residenceStatus?: string
  residenceCost?: string
  needsVehicleInfo: boolean
  needsExistingLoanAmount: boolean
  needsVehicleMonthlyPayment: boolean
  needsResidenceStatus: boolean
  needsResidenceCost: boolean
  needsVehicleMileage: boolean
  employmentStatus: string
  livedInAddress: {
    years: number
    months: number
  }
  findBy: VehicleFindByKeys
  mileage?: string
  existingLoanAmount?: string
  vehicleMonthlyPayment?: string
} & DynamicVehicleFields

type MissingInfoFormSubmission = {
  estimatedAnnualIncome: number
  citizenship: string
  residenceStatus?: string
  residenceCost?: number
  monthsInResidence: string
  mileage?: number
  existingLoanAmount?: number
  vehicleMonthlyPayment?: number
}

/**
 * @deprecated moving towards using prequalSubmitMissingInfo for upserts to refi
 */
export const SubmitMissingInfo = async (
  missingInfoParams: MissingInfoFormParams & { nadaUuid?: string },
  requestId: string,
  leadSource: string,
): Promise<void> => {
  const missingInfo: MissingInfoFormSubmission = {
    ...missingInfoParams,
    ...missingInfoParams.dynamic,
    monthsInResidence: `${
      missingInfoParams.livedInAddress.years * 12 +
      missingInfoParams.livedInAddress.months
    }`,
    estimatedAnnualIncome: parseInteger(
      missingInfoParams.estimatedAnnualIncome,
    ),
    residenceStatus: missingInfoParams.residenceStatus,
    residenceCost:
      missingInfoParams.residenceCost &&
      parseInteger(missingInfoParams.residenceCost),
    mileage:
      missingInfoParams.mileage && parseInteger(missingInfoParams.mileage),
    existingLoanAmount:
      missingInfoParams.existingLoanAmount &&
      parseInteger(missingInfoParams.existingLoanAmount),
    vehicleMonthlyPayment:
      missingInfoParams.vehicleMonthlyPayment &&
      parseInteger(missingInfoParams.vehicleMonthlyPayment),
  }
  const body = JSON.stringify({ ...missingInfo })
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/experian/${requestId}/missing-info?leadSource=${leadSource}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body,
    },
  )
  if (!response.ok) {
    throw new Error('failed to submit additional info')
  }
}

type SubmitInfoCallRejection = {
  error: Error
  operation: string
}

export const prequalSubmitMissingInfo = async (
  prequalAdditionalInfo: AdditionalInformationNeededForm,
  logger: Logger,
): Promise<void> => {
  const {
    applicant,
    residence,
    vehicle,
    existingAutoLoan,
    needsApplicantData,
    needsResidenceData,
    needsVehicleData,
    needsExistingAutoLoanData,
  } = prequalAdditionalInfo

  const apiCalls = []

  apiCalls.push(
    insertLegalAcceptance({
      customerUuid: applicant.primaryCustomerUuid,
      document: DocumentType.CommunicationConsent,
    }).catch(
      (error): SubmitInfoCallRejection => ({
        error,
        operation: 'insertLegalAcceptance',
      }),
    ),
  )

  if (needsApplicantData) {
    apiCalls.push(
      refiUpdateCustomer(
        applicant.primaryCustomerUuid,
        applicant.citizenship,
        applicant.estimatedAnnualIncome,
      ).catch(
        (error): SubmitInfoCallRejection => ({
          error,
          operation: 'refiUpdateCustomer',
        }),
      ),

      refiUpsertCurrentEmployment(applicant.primaryCustomerUuid, {
        id: applicant.employmentId,
        employmentStatus: applicant.employmentStatus,
        monthlyGrossIncome: parseInteger(applicant.estimatedAnnualIncome) / 12,
      }).catch(
        (error): SubmitInfoCallRejection => ({
          error,
          operation: 'refiUpsertCurrentEmployment',
        }),
      ),
    )
  }

  if (needsResidenceData) {
    apiCalls.push(
      updateCurrentResidence(
        residence.residenceUuid,
        applicant.primaryCustomerUuid,
        residence.addressUuid,
        residence.livedInYears,
        residence.livedInMonths,
        residence.residenceStatus,
        residence.monthlyPayment,
      ).catch(
        (error): SubmitInfoCallRejection => ({
          error,
          operation: 'updateCurrentResidence',
        }),
      ),
    )
  }
  // We need to check needsVehicleData or vehicle.mileage is present
  // because there is a possibility that refi doesn't need an update to (vin, license plate, YMMT),
  // but does need an update to mileage. This is because there is a deviation from the
  // use-prequalification-data vehicle and the Additional-Information-needed form type.
  if (needsVehicleData || vehicle.mileage) {
    const commonVehicleAttributes: Common = {
      estimatedMileage: parseInteger(vehicle.mileage),
      loanValue: parseInteger(existingAutoLoan.loanBalance),
      estimatedMonthlyPayment: parseInteger(
        existingAutoLoan.estimatedMonthlyPayment,
      ),
    }

    const refiVehicle: SubmittedVehicle =
      vehicle.findBy === VehicleFindBy.VIN
        ? {
            vin: vehicle.vin,
          }
        : vehicle.findBy === VehicleFindBy.LICENSEPLATE
        ? {
            licensePlateNumber: vehicle.licensePlateNumber,
            licensePlateState: vehicle.state,
          }
        : {
            nadaUid: vehicle.trim,
          }

    apiCalls.push(
      refiUpsertVehicle(vehicle.vehicleUuid, {
        ...commonVehicleAttributes,
        ...(needsVehicleData && refiVehicle),
      }).catch(
        (error): SubmitInfoCallRejection => ({
          error,
          operation: 'refiUpsertVehicle',
        }),
      ),
    )
  }

  if (needsExistingAutoLoanData) {
    apiCalls.push(
      updateLoanApplication(existingAutoLoan.loanApplicationUuid, {
        estimatedMonthlyPayment: parseInteger(
          existingAutoLoan.estimatedMonthlyPayment,
        ),
        estimatedRemainingLoanAmount: parseInteger(
          existingAutoLoan.loanBalance,
        ),
      }).catch(
        (error): SubmitInfoCallRejection => ({
          error,
          operation: 'updateLoanApplication',
        }),
      ),
    )
  }

  const result = await Promise.allSettled(apiCalls)

  result.map((call) => {
    if (isPromiseRejected<SubmitInfoCallRejection>(call)) {
      logger.error('Failed on a call to submit prequal missing info', {
        operation: call.reason.operation,
        error: call.reason.error,
      })
    }
  })
}
