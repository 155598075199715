/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react'
import { UnpackNestedValue, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { PageRoute } from '@common/types'

import { submitAcceptedOffer } from '../../../api'
import { useGetNextUrlByLoanState, useResolver } from '../../../hooks'
import { OfferSelectionsForm, offerSelectionsSchema } from '../../schemas'

type OfferSelectionsFormData = {
  selectedOffer?: string
}

export const useOfferSelectionsForm = () => {
  const { setValue, formState, watch, handleSubmit, getValues } =
    useForm<OfferSelectionsForm>({
      mode: 'all',
      shouldFocusError: true,
      resolver: useResolver(offerSelectionsSchema),
    })
  const router = useRouter()
  const selectedOffer = watch('selectedOffer')
  const getNextUrl = useGetNextUrlByLoanState()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.CreateAccount)
      await router.prefetch(PageRoute.IncomeVerification)
    }
    prefetch().catch(console.error)
  }, [router])

  const submit = async (data: UnpackNestedValue<OfferSelectionsFormData>) => {
    await submitAcceptedOffer(data.selectedOffer)
    const resumeUrl = await getNextUrl()
    void router.push(resumeUrl)
  }

  const toggleOffer = (id: string): void => {
    const selected = getValues('selectedOffer') === id
    setValue('selectedOffer', selected ? null : id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const selectOffer = (id: string): void => {
    setValue('selectedOffer', id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return {
    handleSubmit,
    selectedOffer,
    toggleOffer,
    selectOffer,
    submit,
    formState,
  }
}
